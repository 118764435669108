import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Article from '../components/article'
import HeroVideo from '../components/hero-video'
import Topics from '../components/topics'
import Layout from '../layouts/layout'
import { buildTagRelationships } from '../utilities/helpers'

interface IArticle {
    id: string,
    title: string,
    redirectUrl?: string,
    slug: string,
    date?: Date,
    short_description: ShortDesc,
    tags?: Tag[]
}

interface ShortDesc {
    short_description: String
}

interface Tag {
    tagName: string,
    slug: string,
    id: string,
    articles?: IArticle[]
}

const InsightsPage = () => {

    const data = useStaticQuery(graphql`
        query InsightsQuery {
            allContentfulArticle {
                nodes {
                    date
                    title
                    id
                    tags {
                        tagName
                        slug
                        id
                    }
                    redirectUrl
                    short_description {
                        short_description
                    }
                    slug
                }
            }
            videoData: contentfulLayout(contentful_id: {eq: "T3H8XFGXUuivr4KGfj91v"}) {
                hero {
                    header_title
                    hero_body {
                      hero_body
                    }
                    heroImage {
                      url
                    }
                }
            }
            contentData: contentfulLayout(contentful_id: {eq: "2nNI1eDW3BQWmxU51ShqnP"}) {
                contentModule {
                    ... on ContentfulContentObject {
                        title
                        description {
                        description
                        }
                        media {
                            url
                        }
                        link_text
                    }
                }
                hero {
                    header_title
                }
            }
        }
    `)

    const page = data.contentData
    const videoPage = data.videoData
    const articles: IArticle[] = data.allContentfulArticle.nodes

    const heroTitle = page.hero.header_title
    const heroImage = videoPage.hero.heroImage.url

    return(
        <Layout>
            <Helmet>
                <title>O3 Edge | Blog</title>
            </Helmet>
            <HeroVideo title={heroTitle} video={heroImage} />

            <div className="section-container">
                <Container>
                    <Row>
                        <Topics tags={buildTagRelationships(articles)} />
                        <Col size="12" lg="9">
                            {articles.map(article =>
                                <Article key={`article-${article.id}`} date={article.date} title={article.title} redirectUrl={article.redirectUrl} shortDesc={article.short_description.short_description} slug={article.slug} />
                                )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}

export default InsightsPage